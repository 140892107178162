import React from "react";
import { InputAnswerArea } from "./StyledComponent";

const CompleterEnonces = ({ item, consigneValues, setConsigneValues, handleAddWord }) => {
    return (
        <div>
            <p>Complétez la phrase suivante :</p>
            <p>
                {item.consigne.map((word, index) =>
                    item.correction_dp.includes(word) ? (
                        <InputAnswerArea
                            key={index}
                            value={consigneValues[index]?.userValue || ""}
                            onChange={(e) => {
                                const updatedValues = [...consigneValues];
                                updatedValues[index].userValue = e.target.value;
                                setConsigneValues(updatedValues);
                            }}
                            placeholder="?"
                        />
                    ) : (
                        <span key={index}>{word} </span>
                    )
                )}
            </p>
        </div>
    );
};

export default CompleterEnonces;
