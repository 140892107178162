import React, { useEffect, useState } from "react";
import { AnswerArea, Card } from "./StyledComponent";

const Od = ({ item, data, current, setUserAnswer, handleDrag, handleDragOver }) => {
    const [consigneValues, setConsigneValues] = useState([]);

    // Initialize consigneValues based on item.consigne
    useEffect(() => {
        if (item?.consigne) {
            const values = item.consigne.map((consigneItem) => {
                try {
                    return JSON.parse(consigneItem);
                } catch (error) {
                    console.error("Failed to parse consigneItem:", consigneItem, error);
                    return { value: "", userValue: "" }; // Default fallback object
                }
            });
            setConsigneValues(values);
        } else {
            setConsigneValues([]);
        }
    }, [item]);

    // Update userAnswer based on consigneValues
    useEffect(() => {
        const updatedUserAnswer = consigneValues
            .map((consigne) => consigne.userValue || consigne.value || "")
            .filter((value) => value !== ""); // Exclude empty values
        setUserAnswer(updatedUserAnswer);
    }, [consigneValues, setUserAnswer]);

    const handleCardClick = (cardValue) => {
        // Find the first empty answer slot
        const firstEmptyIndex = consigneValues.findIndex((consigne) => !consigne.userValue);

        if (firstEmptyIndex !== -1) {
            // Update consigneValues with the clicked card value
            const updatedValues = [...consigneValues];
            updatedValues[firstEmptyIndex] = {
                ...updatedValues[firstEmptyIndex],
                userValue: cardValue,
            };
            setConsigneValues(updatedValues);
        }
    };

    if (!item || !item.consigne || !Array.isArray(item.consigne)) {
        return <div>Invalid data for od type</div>;
    }

    return (
        <div>
            <p className="py-4">Complétez les réponses :</p>
            <div className="flex gap-4 flex-wrap items-center justify-center">
                {consigneValues.map((consigne, index) => (
                    <AnswerArea
                        key={index}
                        onDrop={(e) => {
                            e.preventDefault();
                            const droppedValue = e.dataTransfer.getData("cardValue"); // Retrieve the value
                            console.log("Dropped Value:", droppedValue);

                            // Update consigneValues with the dropped value
                            const updatedValues = [...consigneValues];
                            updatedValues[index] = { ...consigne, userValue: droppedValue };
                            setConsigneValues(updatedValues);
                        }}
                        onDragOver={handleDragOver}
                    >
                        <b>{consigne.userValue || "?"}</b>
                    </AnswerArea>
                ))}
            </div>
            <div className="flex gap-4 flex-wrap items-center justify-center mt-4">
                {item?.consigne.map((consigneItem, index) => {
                    const parsedItem = JSON.parse(consigneItem);

                    return (
                        <Card
                            key={index}
                            draggable
                            onDragStart={(e) => {
                                e.dataTransfer.setData("cardValue", parsedItem); // Set the value
                                console.log("Dragging Value:", parsedItem);
                            }}
                            onClick={() => handleCardClick(parsedItem.value)}
                            style={{
                                borderColor: consigneValues.some((c) => c.userValue === parsedItem.value)
                                    ? "green"
                                    : "#ccc",
                            }}
                        >
                            {parsedItem}
                        </Card>
                    );
                })}
            </div>
        </div>
    );
};

export default Od;
