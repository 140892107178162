import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { persistor, store } from "redux/app/Store";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "routes/app.routes";
import CacheBuster from "CashBuster";

// console.log = () => {};

function App() {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return <p className="text-center mt-4">Chargement en cours...</p>;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }

        return (
          <>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <Routes />
              </PersistGate>
            </Provider>
          </>
        );
      }}
    </CacheBuster>
  );
}

export default App;
