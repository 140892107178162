import React from "react";
import { InputAnswerArea } from "./StyledComponent";

const MissingNumbers = ({ item, consigneValues, setConsigneValues, handleAddWord }) => {
    return (
        <div>
            <p>Remplissez les cases vides</p>
            <div className="flex-col flex-wrap items-center mt-4 justify-center text-center gap-4">
                {item?.consigne
                    .map((consigneItem) => JSON.parse(consigneItem))
                    .map((parsedItem, index) => (
                        parsedItem.value !== "" ? (
                            <InputAnswerArea
                                disabled
                                value={parsedItem.value}
                                className="mb-3 p-2 font-bold"
                            />
                        ) : (
                            <InputAnswerArea
                                key={index}
                                value={consigneValues[index]?.userValue || ""}
                                className="mb-3 p-2"
                                onChange={(e) => {
                                    const updatedValues = [...consigneValues];
                                    updatedValues[index].userValue = e.target.value;
                                    setConsigneValues(updatedValues);
                                }}
                                onBlur={(e) => handleAddWord(e.target.value)}
                                placeholder="?"
                            />
                        )
                    ))}
            </div>
        </div>
    );
};

export default MissingNumbers;
