import React from "react";
import { InputAnswerAreaForLetters } from "./StyledComponent";
import TextSpeachWriteInNumber from "../TextSpeachWriteInNumber";

const NumberDictation = ({ item, consigneValues, setConsigneValues, handleAddWord }) => {
    return (
        <div className="flex flex-col items-center gap-10 pt-16">
            <TextSpeachWriteInNumber text={item?.consigne.map((c) => JSON.parse(c))} />
            {item?.consigne.map((_, index) => (
                <InputAnswerAreaForLetters
                    key={index}
                    value={consigneValues[index]?.userValue || ""}
                    placeholder="Votre réponse"
                    onChange={(e) => {
                        const updatedValues = [...consigneValues];
                        updatedValues[index] = { ...updatedValues[index], userValue: e.target.value };
                        setConsigneValues(updatedValues);
                    }}
                // onBlur={(e) => handleAddWord(e.target.value)}
                />
            ))}
        </div>
    );
};

export default NumberDictation;
