import React, { useEffect, useRef, useState } from "react";
import {
  Loader,
  Microphone,
  PlayerPause,
  PlayerPlay,
  Send,
  Trash,
} from "tabler-icons-react";
import MicRecorder from "mic-recorder-to-mp3";
import youngGirl from "../../../assets/images/young_girl.png";
import recordingGif from "../../../assets/audios/recording.gif";
// import { spliterSentences } from "./TextSpeach";

export const spliterSentences = (text) => {
  let newText = [];
  if (text?.includes("-")) {
    newText = text?.split("-");
  } else {
    newText = [text];
  }

  return newText;
};

const AudioRecording = ({
  data,
  onSelect,
  current,
  resetTranscription,
  onGetWordSelected,
  getTimer,
}) => {
  const [curretWord, setCurrentWord] = useState(0);
  const [isLoading] = useState(false);
  const [selectionWord, setSelectionWord] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  const [isReader, setReader] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audio, setAudio] = useState(null);
  const setFile_ = useState(null)[1];
  const [recorder] = useState(new MicRecorder({ bitRate: 128 }));
  // const [timer, setTimer] = useState(0);
  const refAudio = useRef();

  useEffect(() => {
    setSelectionWord([]);
    setCurrentWord(0);
  }, [current]);

  const isRecordingSupported = () => {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  };

  const unlockAudioContext = () => {
    if (window.audioContext && window.audioContext.state === "suspended") {
      const silentBuffer = window.audioContext.createBuffer(1, 1, 22050);
      const source = window.audioContext.createBufferSource();
      source.buffer = silentBuffer;
      source.connect(window.audioContext.destination);
      source.start();
    }
  };

  const startRecording = () => {
    if (!window.audioContext) {
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      window.audioContext = new window.AudioContext();
    }
    unlockAudioContext();
    recorder
      .start()
      .then(() => {
        setIsRecording(true);
        setIsPaused(false);
      })
      .catch((e) => {
        console.error("Error starting recording:", e);
        // Handle the error (e.g., show a message to the user)
      });
  };

  const stopRecording = (stop = false) => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "audio.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        if (stop) {
          setFile_(file);
        }
        addAudioElement(file, stop);
        const audioURL = URL.createObjectURL(file);
        setAudio(audioURL);
        setIsRecording(false);
      })
      .catch((e) => {
        console.error("Error stopping recording:", e);
      });
  };

  const cancelRecording = () => {
    recorder.stop();
    setIsRecording(false);
    setIsPaused(false);
    setAudio(null);
  };

  const addAudioElement = async (file, stop) => {
    // return console.log({ refAudio: refAudio?.current, recorder });

    if (!file) {
      return;
    }
    if (getTimer) {
      getTimer(recorder?.context?.currentTime);
    }

    setSelectionWord([
      ...selectionWord.filter((item) => item.id !== curretWord),
      {
        item: spliterSentences(data?.correction_dp.join(" "))[curretWord],
        id: curretWord,
        file,
      },
    ]);
    onGetWordSelected([
      ...selectionWord.filter((item) => item.id !== curretWord),
      {
        item: spliterSentences(data?.correction_dp.join(" "))[curretWord],
        id: curretWord,
        file,
      },
    ]);

    if (!stop) {
      setCurrentWord(
        spliterSentences(data?.correction_dp.join(" ")).length === curretWord + 1
          ? curretWord
          : curretWord + 1
      );
    }
  };

  return (
    <div className="mt-4">
      <div className="flex w-full gap-6">
        <img src={youngGirl} alt="young_girl" className="h-48 " />
        <div className="flex flex-col gap-2">
          {/* <h3 className="px-3 rounded-full flex items-center justify-center bg-[#9993] font-bold self-end">
            {recorder?.context?.currentTime || 0} s
          </h3> */}
          <div
            className={`text-balance speech-bubble px-4 py-2 border border-[#9992] bg-[#9991] text-left h-fit ${spliterSentences(data?.correction_dp.join(" "))[curretWord]?.split(" ")
                .length > 2
                ? "text-md"
                : "text-[1.8rem]"
              } font-bold`}
          >
            {spliterSentences(data?.correction_dp.join(" "))[curretWord]}
          </div>
        </div>
      </div>

      <div className="absolute left-0 right-0 bottom-4 grid place-items-center">
        {isRecording || isReader ? (
          <div className="p-1 flex flex-col gap-3 py-2 text-[white] ">
            <div className="flex items-center gap-2 p-2">
              <div className="flex-1 flex justify-center">
                {isReader ? (
                  <div className="scale-90">
                    <audio src={audio} controls ref={refAudio} />
                  </div>
                ) : (
                  <img src={recordingGif} alt="recording" className="h-24" />
                )}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <button
                className="p-2 text-danger"
                onClick={() => {
                  setReader(false);
                  cancelRecording();
                }}
              >
                <Trash />
              </button>
              <button
                className="p-2"
                onClick={() => {
                  setReader(true);
                  setIsPaused(true);
                  stopRecording(true);
                }}
              >
                {isReader ? null : isPaused ? <PlayerPlay /> : <PlayerPause />}
              </button>
              <button
                className="bg-[green] p-3 text-[white] rounded-full"
                onClick={() => {
                  if (isPaused) {
                    setIsRecording(false);
                    setReader(false);
                    setIsPaused(false);
                    setAudio(null);
                    setCurrentWord(
                      spliterSentences(data?.correction_dp.join(" ")).length ===
                        curretWord + 1
                        ? curretWord
                        : curretWord + 1
                    );
                  } else {
                    stopRecording(false);
                  }
                }}
              >
                <Send />
              </button>
            </div>
          </div>
        ) : spliterSentences(data?.correction_dp.join(" "))?.length ===
          selectionWord?.length ? null : (
          <div className="flex items-center  justify-center gap-4 p-1 mt-4">
            {isLoading ? (
              <div className="p-3">
                <Loader className="animate-spin" />
              </div>
            ) : isRecordingSupported() ? (
              <button
                className="bg-primary rounded-full p-6 text-white cursor-pointer disabled:opacity-70"
                onClick={startRecording}
              >
                <Microphone
                  color={isRecording ? "red" : "#fff"}
                // className="animate-bounce"
                />
              </button>
            ) : (
              <p>Audio recording is not supported on this device.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AudioRecording;
