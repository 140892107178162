import React, { useState, useEffect } from "react";
import MissingNumbers from "./MissingNumbers";
import WordToNumbers from "./WordToNumbers";
import TrouverLInconnu from "./TrouverLInconnu";
import Operation from "./Operation";
import CompleterEnonces from "./CompleterEnonces";
import ResolutionProbleme from "./ResolutionProbleme";
import NumberDictation from "./NumberDictation";
import WinWil from "./WinWil";
import Od from "./Od";

const MathCardContainer = ({
    data,       // Array of diagnostic data
    current,
    item,
    handleAddWord,
    handleRemoveWord,
    handleDrop,
    handleDragOver,
    userAnswer,
    setUserAnswer,
    selected,
}) => {
    const [consigneValues, setConsigneValues] = useState([]);

    // Initialize consigneValues based on item.consigne
    useEffect(() => {
        if (item?.consigne) {
            setConsigneValues(
                item.consigne.map((c) =>
                    typeof c === "string" ? { value: "", userValue: "" } : c
                )
            );
        } else {
            setConsigneValues([]);
        }
    }, [item]);

    // Update userAnswer whenever consigneValues changes
    useEffect(() => {
        if (item?.type !== "operation") {
            setUserAnswer(
                consigneValues.map((c) => c.userValue || c.value || "").filter((v) => v !== "")
            );
        }
    }, [consigneValues, item, setUserAnswer]);

    if (!item) return <div>No data available</div>;

    switch (item.type) {
        case "missingNumbers":
            return (
                <MissingNumbers
                    item={item}
                    consigneValues={consigneValues}
                    setConsigneValues={setConsigneValues}
                    handleAddWord={handleAddWord}
                />
            );

        case "wordToNumbers":
        case "numberToWords":
            return (
                <WordToNumbers
                    item={item}
                    consigneValues={consigneValues}
                    setConsigneValues={setConsigneValues}
                />
            );

        case "trouverLInconnu":
            return (
                <TrouverLInconnu
                    item={item}
                    consigneValues={consigneValues}
                    setConsigneValues={setConsigneValues}
                />
            );

        case "operation":
            return (
                <Operation
                    item={item}
                    data={data}
                    current={current}
                    userAnswer={userAnswer}
                    setUserAnswer={setUserAnswer}
                    handleDrag={(e, value) => e.dataTransfer.setData("cardValue", value)}
                    handleDragOver={(e) => e.preventDefault()}
                />
            );



        case "completerEnonces":
            return (
                <CompleterEnonces
                    item={item}
                    consigneValues={consigneValues}
                    setConsigneValues={setConsigneValues}
                    handleAddWord={handleAddWord}
                />
            );

        case "resolutionProbleme":
            return (
                <ResolutionProbleme
                    item={item}
                    consigneValues={consigneValues}
                    setConsigneValues={setConsigneValues}
                    handleAddWord={handleAddWord}
                />
            );

        case "numberDictation":
            return (
                <NumberDictation
                    item={item}
                    consigneValues={consigneValues}
                    setConsigneValues={setConsigneValues}
                />
            );

        case "od":
            return (
                <Od
                    item={item}
                    data={data}
                    current={current}
                    setUserAnswer={setUserAnswer}
                    handleDrag={(e, value) => e.dataTransfer.setData("cardValue", value)}
                    handleDragOver={(e) => e.preventDefault()}
                />
            );

        case "win":
        case "wil":
            return <WinWil item={item} />;

        default:
            return <div>Unsupported diagnostic type</div>;
    }
};

export default MathCardContainer;
