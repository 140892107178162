import React, { useState, useEffect } from "react";
import { PlayerPause, PlayerPlay, PlayerStop } from "tabler-icons-react";

function TextSpeachWriteInNumber({ text = [], onChange, textRecorded }) {
  const [isPaused, setIsPaused] = useState(false);
  const [utterance, setUtterance] = useState(null);

  useEffect(() => {
    const synth = window.speechSynthesis;

    // Create a new SpeechSynthesisUtterance
    const u = new SpeechSynthesisUtterance(
      text.length > 0 ? text.join("; ") : "Pas de consigne"
    );

    // Configure utterance properties
    const voices = synth.getVoices();
    u.voice = voices.find((voice) => voice.lang === "fr-FR") || null;
    u.volume = 1; // Volume from 0 to 1
    u.rate = 0.8; // Rate from 0.1 to 10
    u.pitch = 1; // Pitch from 0 to 2
    u.lang = "fr-FR";

    // Add an event listener for when the speech ends
    u.onend = () => {
      setIsPaused(false); // Reset state when speech ends
    };

    setUtterance(u);

    return () => {
      synth.cancel(); // Cleanup speech synthesis on unmount
    };
  }, [text]);

  const handlePlay = () => {
    const synth = window.speechSynthesis;

    if (!isPaused && utterance) {
      synth.speak(utterance);
      setIsPaused(true); // Mark speech as active
    }
  };

  const handlePause = () => {
    const synth = window.speechSynthesis;

    if (isPaused) {
      synth.pause();
      setIsPaused(false); // Mark speech as paused
    }
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;
    synth.cancel(); // Stop any ongoing speech
    setIsPaused(false); // Reset pause state
  };

  return (
    <div className="flex justify-center cursor-pointer">
      <div className="bg-primary rounded-full p-4 text-white">
        {isPaused ? (
          <PlayerPause size={20} onClick={handlePause} />
        ) : (
          <PlayerPlay size={20} onClick={handlePlay} />
        )}
      </div>
      <button
        onClick={handleStop}
        className="ml-4 bg-secondary rounded-full p-4 text-white"
      >
        <PlayerStop size={20} />
      </button>
    </div>
  );
}

export default TextSpeachWriteInNumber;
